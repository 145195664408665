<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card p-grid">
                <Toast />
                <div class="p-col-12 p-md-4">
                    <h5>{{ fixture.Name }}</h5>
                    <h6>{{ fixture.Date }}</h6>
                </div>
                <div class="p-col-12 p-md-4 p-text-center">
                    <div>{{ $t('sportsbets.oddsUpdateText') }} {{ fixture.ManualOdds ? 'manual' : 'auto' }}</div>
                    <Button :label="$t('buttons.autoOdds')" v-if="fixture.ManualOdds" :class="'p-button-text p-ml-2 p-mt-2 p-button-warning'" @click="confirmAuto()" />
                </div>
                <div class="p-col-12 p-md-4 p-text-right">
                    <label class="p-col-12 p-mt-2 p-md-1 p-mb-md-0">{{ $t('sportsbets.inOffer') }}</label>
                    <InputSwitch v-model="fixture.InOffer" class="p-mt-2" />
                </div>
                <div class="p-col-6 p-text-left">
                    <Button
                        v-if="!isTopOfferBool"
                        @click="goto('addSpecialMarket', { sportId: fixture.SportId, fixtureId: fixture.FixtureId, fixturesActive: fixturesActive, backRoute: backRoute })"
                        :label="$t('buttons.addSpecialMarkets')"
                        class="p-button-raised p-button-info p-mr-2 p-mb-2"
                    />
                </div>
                <div class="p-col-6 p-text-right">
                    <Button @click="confirmResults()" :label="$t('buttons.save')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
                    <Button :label="$t('buttons.cancel')" class="p-button-raised p-button-danger p-mr-2 p-mb-2" @click="goto(backRoute)" />
                </div>
                <Fieldset :legend="$t('results.eventMarkets')" :toggleable="true" :collapsed="false" class="p-col-12 p-mb-2">
                    <div class="p-col-12 p-grid">
                        <div class="p-col-6 p-md-2 p-mb-2">
                            <span class="p-float-label">
                                <Dropdown id="marketsDropdown" style="width: 100%" v-model="selectedMarket" :options="markets" optionLabel="name" :filter="!this.isMobile()"  />
                                <label for="marketsDropdown">{{ $t('dropdown.placeholder.market') }}</label>
                            </span>
                        </div>
                        <div class="p-col-6 p-md-2 p-mb-2">
                            <span class="p-float-label">
                                <Dropdown id="statusDropdown" style="width: 100%" v-model="selectedStatus" :options="marketsStatus" optionLabel="name"  :filter="!this.isMobile()"  />
                                <label for="statusDropdown">{{ $t('dropdown.placeholder.status') }}</label>
                            </span>
                        </div>
                        <div class="card p-col-12 p-grid p-mb-3" v-for="(market, index) in filteredData" :key="'market' + index" v-show="!showFixtureLoading">
                            <div class="p-col-12 p-md-6">
                                <span
                                    ><b>{{ market.Name }}</b> {{ market.Specifier }}</span
                                >
                            </div>
                            <div class="p-col-6 p-md-4 p-text-right">
                                <label class="p-col-12 p-mt-2 p-md-1 p-mb-md-0">{{ $t('sportsbets.inOffer') }}</label>
                                <InputSwitch v-model="market.InOffer" class="p-mt-2" />
                            </div>
                            <div class="p-col-6 p-md-2 p-text-right">
                                <Button :label="market.Status" :class="'p-button-outlined p-mr-2 p-mb-2 p-button-' + getStatusColor(market.Status)" />
                            </div>
                            <div v-for="(outcome, index) in market.Outcomes" :key="'outcome' + market.Id + '_' + outcome.Id + '_' + index" class="p-col-12 p-grid p-pb-0 p-pt-0">
                                <div class="p-col-4 p-mt-2">{{ outcome.Name }}</div>
                                <div class="p-col-4 p-mt-2">{{ outcome.Odds }}</div>
                                <div class="p-col-4 p-text-right">
                                    <span class="p-float-label">
                                        <InputText id="newOdd" style="width: 100%" type="text" v-model="outcome.Odds" />
                                        <label for="newOdd">{{ $t('sportsbets.newOdd') }}</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fieldset>
                <Dialog :header="$t('sportsbets.dialog.updatesConfirmation')" v-model:visible="showUpdatesDialog" :breakpoints="{ '960px': '75vw' }" :style="{ width: '30vw' }" :modal="true">
                    <p v-html="modalText"></p>
                    <template #footer>
                        <Button :label="$t('buttons.close')" @click="closeModals()" icon="pi pi-times" class="p-button-danger" />
                        <Button :label="$t('buttons.confirm')" @click="addResults()" icon="pi pi-check" class="p-button-success" />
                    </template>
                </Dialog>
                <Dialog :header="$t('sportsbets.dialog.updatesConfirmation')" v-model:visible="showAutoDialog" :breakpoints="{ '960px': '75vw' }" :style="{ width: '30vw' }" :modal="true">
                    <p v-html="$t('sportsbets.dialog.confirmAutoOdds')"></p>
                    <template #footer>
                        <Button :label="$t('buttons.close')" @click="closeModals()" icon="pi pi-times" class="p-button-danger" />
                        <Button :label="$t('buttons.confirm')" @click="backToAutoOdds()" icon="pi pi-check" class="p-button-success" />
                    </template>
                </Dialog>
            </div>
        </div>
    </div>
</template>

<script>
import { SportsApi } from '../../service/SportsApi';
import { BoSportsApi } from '../../service/BoSportsApi';
export default {
    name: 'eventDetails',
    data() {
        return {
            fixture: {
                Markets: [],
                Results: { HomeScore: '', AwayScore: '', Periods: [] },
                Name: '',
                Date: '',
            },
            SportsApi: new SportsApi(),
            BoSportsApi: new BoSportsApi(),
            markets: [],
            selectedStatus: { name: 'All', value: '0' },
            selectedMarket: { name: 'All', id: '0' },
            marketsStatus: [
                {
                    name: 'All',
                    value: '0',
                },
                {
                    name: 'SETTLED',
                    value: '1',
                },
                {
                    name: 'INACTIVE',
                    value: '2',
                },
                {
                    name: 'SUSPENDED',
                    value: '3',
                },
                {
                    name: 'ACTIVE',
                    value: '4',
                },
            ],
            initialFixture: {},
            errorKeyCount: 0,
            showModal: false,
            changedOdds: 0,
            changedOutcomes: [],
            showAutoDialog: false,
            showUpdatesDialog: false,
            removedMarkets: [],
            addedMarkets: [],
            fixtureStatusChanged: false,
        };
    },
    computed: {
        filteredData() {
            let markets = [];
            if (this.selectedMarket.id === '0' && this.selectedStatus.value === '0') {
                markets = this.fixture.Markets;
            }

            markets = this.fixture.Markets.filter((item) => {
                return (this.selectedMarket.id === '0' || item._id === this.selectedMarket.id) && (this.selectedStatus.value === '0' || item.Status === this.selectedStatus.name);
            });
            return markets;
        },
        fixturesActiveBool() {
            return String(this.fixturesActive).toLowerCase() === 'true';
        },
        isTopOfferBool() {
            return String(this.isTopOffer).toLowerCase() === 'true';
        },
        modalText() {
            //test
            var text = this.$t('sportsbets.dialog.saveChanges') + '<br/><br/>';
            if (this.fixtureStatusChanged) {
                this.fixture.InOffer ? (text += this.$t('sportsbets.dialog.fixtureAdded') + '<br/>') : (text += this.$t('sportsbets.dialog.fixtureRemoved') + '<br/>');
            }
            this.addedMarkets.length > 0 ? (text += this.addedMarkets.length + this.$t('sportsbets.dialog.marketsAdded') + '<br/>') : (text += '');
            this.removedMarkets.length > 0 ? (text += this.removedMarkets.length + this.$t('sportsbets.dialog.marketsRemoved') + '<br/>') : (text += '');
            this.changedOutcomes.length > 0 ? (text += this.changedOutcomes.length + this.$t('sportsbets.dialog.outcomesChanged') + '<br/>') : (text += '');
            return text;
        },
    },
    created() {
        if (this.fixturesActiveBool) {
            this.SportsApi.getFixtureDetails(this.fixtureId, this.isTopOfferBool)
                .then((response) => {
                    this.fixture = response.data;
                    this.initialFixture = JSON.parse(JSON.stringify(response.data));
                    if (this.fixture.Results == null) {
                        this.fixture.Results = { HomeScore: '', AwayScore: '', Periods: [] };
                    }
                    this.fixture.Date = this.formatCSDateAndTime(response.data.Date);
                    this.showFixtureLoading = false;
                    var marketsArray = [];
                    for (var i = 0; i < response.data.Markets.length; i++) {
                        var marketObj = {
                            name: response.data.Markets[i].Name,
                            id: response.data.Markets[i]._id,
                        };
                        var marketPosition = marketsArray
                            .map(function (m) {
                                return m.id;
                            })
                            .indexOf(response.data.Markets[i]._id);
                        if (marketPosition < 0) {
                            marketsArray.push(marketObj);
                        }
                    }
                    this.markets = marketsArray.sort(function (a, b) {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    });
                    this.markets.unshift(this.selectedMarket);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        this.$store.commit('login/endUserSession');
                        this.goto('login', { session: 'false' });
                    }
                });
        } else {
            this.SportsApi.getOutrightDetails(this.fixtureId)
                .then((response) => {
                    this.fixture = response.data;
                    this.initialFixture = JSON.parse(JSON.stringify(response.data));
                    var marketsArray = [];
                    for (var i = 0; i < response.data.Markets.length; i++) {
                        var marketObj = {
                            name: response.data.Markets[i].Name,
                            id: response.data.Markets[i]._id,
                        };
                        var marketPosition = marketsArray
                            .map(function (m) {
                                return m.id;
                            })
                            .indexOf(response.data.Markets[i]._id);
                        if (marketPosition < 0) {
                            marketsArray.push(marketObj);
                        }
                    }
                    this.markets = marketsArray.sort(function (a, b) {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    });
                    this.showFixtureLoading = false;
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        this.$store.commit('login/endUserSession');
                        this.goto('login', { session: 'false' });
                    }
                });
        }
    },
    watch: {
        selectedStatus() {
            if (this.selectedStatus === '') {
                this.selectedStatus = { name: 'All', value: '0' };
            }
        },
        selectedMarket() {
            if (this.selectedMarket === '') {
                this.selectedMarket = { name: 'All', id: '0' };
            }
        },
    },
    props: {
        backRoute: {
            type: String,
        },
        fixturesActive: {
            type: String,
            default: 'false',
        },
        fixtureId: {
            type: String,
        },
        isTopOffer: {
            type: String,
            default: 'false',
        },
    },
    methods: {
        getStatusColor(status) {
            switch (status) {
                case 'SETTLED': {
                    return 'success';
                }
                case 'INACTIVE': {
                    return 'danger';
                }
                case 'SUSPENDED': {
                    return 'plain';
                }
                case 'ACTIVE': {
                    return 'info';
                }
            }
        },
        showToast(toastSeverity, text) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: text, life: this.getToastLifeSeconds() });
        },
        closeModals() {
            this.showAutoDialog = false;
            this.showUpdatesDialog = false;
        },
        confirmAuto() {
            this.showAutoDialog = true;
        },
        backToAutoOdds() {
            this.showAutoDialog = false;

            this.BoSportsApi.backToAutoOdds(this.fixtureId, !this.fixturesActiveBool)
                .then(() => {
                    this.showToast('success', this.$t('sportsbets.alerts.changesDone'));
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showToast('error', this.$t('alerts.generalError'));
                });
        },
        confirmResults() {
            this.changedOutcome = [];
            this.removedMarkets = [];
            this.addedMarkets = [];
            this.fixtureStatusChanged = false;
            if (this.fixture.InOffer !== this.initialFixture.InOffer) {
                this.fixtureStatusChanged = true;
            }
            for (var i = 0; i < this.fixture.Markets.length; i++) {
                if (this.fixture.Markets[i].InOffer !== this.initialFixture.Markets[i].InOffer) {
                    var market = {
                        MarketId: this.fixture.Markets[i]._id,
                        Specifier: this.fixture.Markets[i].Specifier,
                    };
                    if (this.fixture.Markets[i].InOffer) {
                        this.addedMarkets.push(market);
                    } else {
                        this.removedMarkets.push(market);
                    }
                }
                for (var j = 0; j < this.fixture.Markets[i].Outcomes.length; j++) {
                    if (this.fixture.Markets[i].Outcomes[j].Odds !== this.initialFixture.Markets[i].Outcomes[j].Odds) {
                        var changedOutcome = {
                            MarketId: this.fixture.Markets[i]._id,
                            Specifier: this.fixture.Markets[i].Specifier,
                            OutcomeId: this.fixture.Markets[i].Outcomes[j]._id,
                            Odds: this.fixture.Markets[i].Outcomes[j].Odds,
                        };
                        this.changedOutcomes.push(changedOutcome);
                        this.changedOdds++;
                    }
                }
            }
            if (this.changedOdds > 0 || this.fixtureStatusChanged || this.removedMarkets.length > 0 || this.addedMarkets.length > 0) {
                this.showUpdatesDialog = true;
            } else {
                this.showToast('info', this.$t('sportsbets.alerts.noChanges'));
            }
        },
        addResults() {
            this.showUpdatesDialog = false;
            this.BoSportsApi.saveFixtureDetails(this.fixtureId, this.fixture.InOffer, this.changedOutcomes, this.addedMarkets, this.removedMarkets, this.isTopOfferBool, !this.fixturesActiveBool)
                .then(() => {
                    this.showToast('success', this.$t('sportsbets.alerts.changesDone'));
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showToast('error', this.$t('alerts.generalError'));
                });
        },
    },
};
</script>
